body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
  min-width: 100vw;
}
